<template>

    <div>
        <v-card :loading="loading" class="mx-auto" outlined>
            <template slot="progress">
                <v-progress-linear
                        :color="$globalSettings.progressbarClasses"
                        height="10"
                        indeterminate
                ></v-progress-linear>
            </template>

            <v-card-title>
                <v-row>
                    <v-col cols="12" sm="5">
                        <div>
                            <v-btn type="button" class="default" :disabled="loading" small icon @click="navigationBack"><v-icon small>fa-angle-left</v-icon></v-btn> <span class="secondary-text">Inspection Report</span>
                            <!--<div class="font-size-12 smaller-line-height ml-8">Status: <span class="font-italic">{{ $globalHelpers.getApplicationStatusName(formData.EA.StatusId) }}</span></div>-->
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div class="font-size-12 smaller-line-height ml-8">
                                        Inspectors:
                                        <ol>
                                            <li v-for="(inspector, i) in formData.EAInspectors" :key="i">
                                                {{ inspector.User.Name + (inspector.isLeader? ', Lead': '') }}<br />
                                                {{ (inspector.User.DesignationId? $globalHelpers.getUserDesignationName(inspector.User.DesignationId) + ', ': '') + $globalHelpers.getExporterTypesName(inspector.User.ExporterType) }},
                                                {{ $globalHelpers.getServiceOfficeName(inspector.User.ServiceOfficeId) }}
                                            </li>
                                        </ol>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6" class="font-size-12 smaller-line-height">
                                    <div>Organization: </div>
                                    {{ formData.EA.User.OrganizationName }}
                                    <div v-if="formData.EA.User.Username">({{ formData.EA.User.Username }})</div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="7" class="text-right">
                        <span v-if="formData.EA.StatusId === $globalEnums.ApplicationStatuses.Inspection && ($globalHelpers.getAuthStoreValue('user_role_id') === $globalEnums.roles.Director || $globalHelpers.getAuthStoreValue('user_role_id') === $globalEnums.roles.DG)">
                            <v-btn type="button" class="ml-1 py-3" small color="warning" :disabled="loading" @click="formActions.viewLetterModal = true">Send Letter <v-icon small class="ml-1">fas fa-envelope</v-icon></v-btn>
                        </span>

                        <v-btn type="button" class="py-3 ml-1" small color="secondary" @click="pdfLetter('exporter')" :disabled="loading"><v-icon small class="mr-1">fas fa-print</v-icon>Exporter Letter</v-btn>
                        <v-btn type="button" class="py-3 ml-1" small color="secondary" @click="pdfLetter('inspector')" :disabled="loading"><v-icon small class="mr-1">fas fa-print</v-icon>Inspector Letter</v-btn>
                        <v-btn type="button" class="py-3 ml-1" small color="secondary" @click="pdfApplication" :disabled="loading"><v-icon small class="mr-1">fas fa-print</v-icon>Application</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <validation-observer ref="observer" v-slot="{ invalid }">
                        <v-row>
                            <v-col sm="3" class="border-right-grey">
                                <v-list dense>
                                    <v-list-item @click="selectSection(100)" :class="{'v-item--active v-list-item--active v-list-item--link theme--light': selectedSectionId === 100 }">
                                        <v-list-item-icon>
                                            <v-icon small v-if="!invalid" class="success--text">fas fa-circle-check</v-icon>
                                            <v-icon small v-else>fas fa-file-pen</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content><v-list-item-title>Inquiry Report <span class="error--text">*</span></v-list-item-title></v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-for="(attachment, i) in attachments" :key="i" @click="selectSection(attachment.id)" :class="{'v-item--active v-list-item--active v-list-item--link theme--light': selectedSectionId === attachment.id }">
                                        <v-list-item-icon>
                                            <v-icon small v-if="isAttachmentSectionComplete(attachment.id)" class="success--text">fas fa-circle-check</v-icon>
                                            <v-icon small v-else>far fa-circle</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ attachment.label}} <span v-if="attachment.required" class="error--text">*</span></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="selectSection(101)" v-if="formData.EA.StatusId > $globalEnums.ApplicationStatuses.Inspection" :class="{'v-item--active v-list-item--active v-list-item--link theme--light': selectedSectionId === 101 }">
                                        <v-list-item-icon>
                                            <v-icon small>fas fa-quote-left</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content><v-list-item-title>Summary</v-list-item-title></v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                            <v-col sm="9">
                                <div v-show="selectedSectionId === 100">
                                    <div class="font-size-20 font-bold">Inquiry Report </div>
                                    <v-divider class="mt-2 mb-5"></v-divider>
                                    <v-form @submit.prevent="save" :disabled="!formActions.formEditEnabled">
                                        <v-card outlined class="mb-4">
                                            <v-card-title>Authorized Contact Person</v-card-title>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12" sm="3">
                                                        <validation-provider v-slot="{ errors }" name="First Name" rules="">
                                                            <v-text-field v-model="formData.OfficePersonFName" type="text" :error-messages="errors" label="First Name" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="3">
                                                        <validation-provider v-slot="{ errors }" name="Last Name" rules="">
                                                            <v-text-field v-model="formData.OfficePersonLName" type="text" :error-messages="errors" label="Last Name" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="3">
                                                        <validation-provider v-slot="{ errors }" name="Title" rules="">
                                                            <v-text-field v-model="formData.OfficePersonTitle" type="text" :error-messages="errors" label="Title" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="3">
                                                        <validation-provider v-slot="{ errors }" name="Phone" rules="">
                                                            <v-text-field v-model="formData.OfficePersonPhone" type="text" :error-messages="errors" label="Phone" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>

                                        <v-card outlined class="mb-4">
                                            <v-card-title>Office Contact</v-card-title>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12" sm="6">
                                                        <validation-provider v-slot="{ errors }" name="Office Address" rules="required">
                                                            <v-text-field v-model="formData.OfficeAddress" type="text" :error-messages="errors" label="Address *" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                        <validation-provider v-slot="{ errors }" name="Office Email" rules="required">
                                                            <v-text-field v-model="formData.OfficeEmail" type="text" :error-messages="errors" label="Email *" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                        <validation-provider v-slot="{ errors }" name="Office Phone" rules="required">
                                                            <v-text-field v-model="formData.OfficePhone" type="text" :error-messages="errors" label="Phone *" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <validation-provider v-slot="{ errors }" name="District" rules="required">
                                                            <v-select v-model="formData.OfficeDistrictId" :items="$globalSettings.districts" item-value="id" item-text="label" label="District *" :error-messages="errors" :disabled="loading"></v-select>
                                                        </validation-provider>
                                                        <validation-provider v-slot="{ errors }" name="District" rules="required">
                                                            <v-select v-model="formData.OfficeThanaId" :items="$globalSettings.thanas.filter(x => x.districtId === formData.OfficeDistrictId)" item-value="id" item-text="label" label="Thana *" :error-messages="errors" :disabled="loading"></v-select>
                                                        </validation-provider>
                                                        <v-text-field v-model="formData.OfficeWebsite" type="text" label="Website" :disabled="loading"></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>

                                        <v-card outlined class="mb-4">
                                            <v-card-title>Factory Contact <v-btn depressed color="primary" @click="formCopyOfficeToFactory" x-small v-if="formActions.formEditEnabled" class="ml-3"><v-icon small x-small class="mr-2">far fa-copy</v-icon>Copy from Office</v-btn></v-card-title>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12" sm="6">
                                                        <validation-provider v-slot="{ errors }" name="FactoryAddress" rules="required">
                                                            <v-text-field v-model="formData.FactoryAddress" type="text" :error-messages="errors" label="Address *" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                        <validation-provider v-slot="{ errors }" name="FactoryEmail" rules="required">
                                                            <v-text-field v-model="formData.FactoryEmail" type="text" :error-messages="errors" label="Email *" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                        <validation-provider v-slot="{ errors }" name="FactoryPhone" rules="required">
                                                            <v-text-field v-model="formData.FactoryPhone" type="text" :error-messages="errors" label="Phone *" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <validation-provider v-slot="{ errors }" name="District" rules="required">
                                                            <v-select v-model="formData.FactoryDistrictId" :items="$globalSettings.districts" item-value="id" item-text="label" label="District *" :error-messages="errors" :disabled="loading"></v-select>
                                                        </validation-provider>
                                                        <validation-provider v-slot="{ errors }" name="District" rules="required">
                                                            <v-select v-model="formData.FactoryThanaId" :items="$globalSettings.thanas.filter(x => x.districtId === formData.FactoryDistrictId)" item-value="id" item-text="label" label="Thana *" :error-messages="errors" :disabled="loading"></v-select>
                                                        </validation-provider>
                                                        <v-text-field v-model="formData.FactoryWebsite" type="text" label="Website" :disabled="loading"></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>

                                        <v-card outlined class="mb-4">
                                            <v-card-title>Business Details</v-card-title>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12" sm="4">
                                                        <validation-provider v-slot="{ errors }" name="TINNo"  rules="required|length:12|numeric">
                                                            <v-text-field v-model="formData.TINNo" type="text" :error-messages="errors" label="Tin No *" :disabled="loading" hint="ex. 123456789012"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <validation-provider v-slot="{ errors }" name="BINNo" rules="required">
                                                            <v-text-field v-model="formData.BINNo" type="text" :error-messages="errors" label="Bin No *" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <validation-provider v-slot="{ errors }" name="Rex No" rules="">
                                                            <v-text-field v-model="formData.RexNo" type="text" :error-messages="errors" label="Rex No" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <validation-provider v-slot="{ errors }" name="BankName" rules="">
                                                            <v-text-field v-model="formData.BankName" type="text" :error-messages="errors" label="Bank Name" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <validation-provider v-slot="{ errors }" name="BankAddress" rules="">
                                                            <v-text-field v-model="formData.BankAddress" type="text" :error-messages="errors" label="Bank Address" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="12">
                                                        <validation-provider v-slot="{ errors }" name="OrgType" rules="">
                                                            <v-radio-group v-model="formData.OrgType" :error-messages="errors" row dense label="Type of Organization">
                                                                <v-radio v-for="item in $globalSettings.inspectionOrgTypes" v-bind:key="item.id" :label="item.label" :value="item.id"></v-radio>
                                                            </v-radio-group>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="12">
                                                        <validation-provider v-slot="{ errors }" name="OrgCat" rules="">
                                                            <v-radio-group v-model="formData.OrgCat" :error-messages="errors" row dense label="Category of Organization">
                                                                <v-radio v-for="item in $globalSettings.inspectionOrgCats" v-bind:key="item.id" :label="item.label" :value="item.id"></v-radio>
                                                            </v-radio-group>
                                                        </validation-provider>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>

                                        <v-card outlined class="mb-4">
                                            <v-card-title>Organization Status</v-card-title>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12" sm="4">
                                                        <validation-provider v-slot="{ errors }" name="InExactLoc" rules="">
                                                            <v-select v-model="formData.InExactLoc" :items="[{id: false, label: 'No'}, {id: true, label: 'Yes'}]" item-value="id" item-text="label" label="Is it in exact location?" :error-messages="errors"></v-select>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <validation-provider v-slot="{ errors }" name="IsActive" rules="">
                                                            <v-select v-model="formData.IsActive" :items="[{id: false, label: 'No'}, {id: true, label: 'Yes'}]" item-value="id" item-text="label" label="Is activities running?" :error-messages="errors"></v-select>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <validation-provider v-slot="{ errors }" name="DirectlyExporting" rules="">
                                                            <v-select v-model="formData.DirectlyExporting" :items="[{id: false, label: 'No'}, {id: true, label: 'Yes'}]" item-value="id" item-text="label" label="Is directly exporting?" :error-messages="errors"></v-select>
                                                        </validation-provider>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>

                                        <v-card outlined class="mb-4">
                                            <v-card-title>Factory Type</v-card-title>
                                            <v-card-text>
                                                <v-row class="mt-2">
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field label="Search" placeholder="Start typing to search" outlined dense v-model="formActions.searchFactoryTypeKeyword" @keyup="searchFactoryType" v-on:keydown.enter.prevent @click:clear="clearFactoryTypeSearch()" clearable></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <validation-provider v-slot="{ errors }" name="Factory Types" rules="required">
                                                    <div v-for="(error, i) in errors" v-bind:key="i" class="red--text">{{ error }}</div>
                                                    <v-row>
                                                        <v-col cols="12" sm="2" class="py-0" v-for="item in formActions.filteredFactoryTypes" v-bind:key="item.Id">
                                                            <v-checkbox v-model="selectedFactoryTypes" :label="item.Label" :value="item.Id" dense></v-checkbox>
                                                        </v-col>
                                                    </v-row>
                                                </validation-provider>
                                            </v-card-text>
                                        </v-card>

                                        <v-card outlined class="mb-4">
                                            <v-card-title>Factory Information</v-card-title>
                                            <v-card-text>
                                                <v-row v-if="formData.EA.User.ExporterType === $globalEnums.ExporterTypes.Textile">
                                                    <v-col cols="12" sm="4">
                                                        <validation-provider v-slot="{ errors }" name="Is Composite" rules="">
                                                            <v-select v-model="formData.IsComposite" :items="[{id: false, label: 'No'}, {id: true, label: 'Yes'}]" item-value="id" item-text="label" label="Is composite?" :error-messages="errors"></v-select>
                                                        </validation-provider>
                                                    </v-col>

                                                    <v-col cols="12" sm="4">
                                                        <validation-provider v-slot="{ errors }" name="FactoryBuildingType" rules="">
                                                            <v-text-field v-model="formData.FactoryBuildingType" type="text" :error-messages="errors" label="Building type" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <validation-provider v-slot="{ errors }" name="FactoryBuildingFloorsCount" rules="">
                                                            <v-text-field v-model="formData.FactoryBuildingFloorsCount" type="text" :error-messages="errors" label="Total Floor count" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <validation-provider v-slot="{ errors }" name="FactoryBuildingProductionFloorsCount" rules="">
                                                            <v-text-field v-model="formData.FactoryBuildingProductionFloorsCount" type="text" :error-messages="errors" label="Production Floor count" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <validation-provider v-slot="{ errors }" name="FactoryBuildingFloorSize" rules="">
                                                            <v-text-field v-model="formData.FactoryBuildingFloorSize" type="text" :error-messages="errors" label="Floor size" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <validation-provider v-slot="{ errors }" name="FactoryBuildingStairsCount" rules="">
                                                            <v-text-field v-model="formData.FactoryBuildingStairsCount" type="text" :error-messages="errors" label="Stairs count" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>

                                                    <v-col cols="12" sm="12">
                                                        <validation-provider v-slot="{ errors }" name="FireEquipmentsDetails" rules="">
                                                            <v-textarea v-model="formData.FireEquipmentsDetails" :error-messages="errors" label="Fire equipments details" rows="2" auto-grow :disabled="loading"></v-textarea>
                                                        </validation-provider>
                                                    </v-col>
                                                </v-row>

                                                <div class="mt-2">Number of machines with details</div>
                                                <v-row>
                                                    <v-col cols="12" sm="6" v-if="formActions.formEditEnabled">
                                                        <v-text-field v-model="formActions.machineDetails" type="text" label="Add machine details" :disabled="loading">
                                                            <template v-slot:append-outer>
                                                                <v-btn type="button" fab x-small class="secondary mr-3" :disabled="!formActions.machineDetails" @click="addMachine"><v-icon dark>fas fa-plus</v-icon></v-btn>
                                                            </template>
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <v-list dense>
                                                            <v-list-item v-for="(machineDetails, i) in selectedMachines" :key="i">
                                                                <v-list-item-content>
                                                                    {{ i + 1 }}. {{ machineDetails }}
                                                                </v-list-item-content>
                                                                <v-list-item-action v-if="formActions.formEditEnabled"><v-btn type="button" fab x-small @click="deleteMachine(i)"><v-icon dense class="red--text" x-small>fas fa-x</v-icon></v-btn></v-list-item-action>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-col>
                                                </v-row>

                                            </v-card-text>
                                        </v-card>

                                        <v-card outlined class="mb-4">
                                            <v-card-title>Production Details</v-card-title>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12" :sm="formActions.formEditEnabled ? 6: 12">
                                                        Products/HS Codes
                                                        <div class="mt-2" style="min-height: 100px;">
                                                            <v-row v-for="(hsCode, i) in formActions.selectedHSCodes" :key="i">
                                                                <v-col sm="2">
                                                                    {{ hsCode.Code }}
                                                                </v-col>
                                                                <v-col sm="8">
                                                                    {{ hsCode.Description }}
                                                                </v-col>
                                                                <v-col sm="2" v-if="formActions.formEditEnabled">
                                                                    <v-btn fab x-small type="button" class="mr-3" :disabled="loading" @click="removeHSCode(hsCode)"><v-icon x-small>fas fa-minus</v-icon></v-btn>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12" sm="6" v-if="formActions.formEditEnabled">
                                                        <v-text-field v-model="formActions.hscodeSearchKeyword" type="text" label="Search & Add HS code" @keyup="searchHSCode"></v-text-field>
                                                        <div class="mt-2" style="min-height: 100px;">
                                                            <v-row v-for="(hsCode, i) in formActions.hsCodeSearchResults" :key="i">
                                                                <v-col sm="2">
                                                                    {{ hsCode.Code }}
                                                                </v-col>
                                                                <v-col sm="8">
                                                                    {{ hsCode.Description }}
                                                                </v-col>
                                                                <v-col sm="2">
                                                                    <v-btn fab x-small type="button" class="mr-3" :disabled="loading" @click="addHSCode(hsCode)"><v-icon x-small>fas fa-plus</v-icon></v-btn>
                                                                </v-col>
                                                            </v-row>
                                                            <i v-if="formActions.hscodeSearchKeyword && formActions.hsCodeSearchResults.length < 1">No HS Code matched your keyword.</i>
                                                        </div>
                                                    </v-col>
                                                </v-row>

                                                <v-row class="mt-5">
                                                    <!--<v-col cols="12" sm="12" class="pt-0">
                                                        <validation-provider v-slot="{ errors }" name="ProductsWithHSCodes" rules="">
                                                            <v-textarea v-model="formData.ProductsWithHSCodes" :error-messages="errors" label="Products with HS Codes" rows="2" auto-grow :disabled="loading"></v-textarea>
                                                        </validation-provider>
                                                    </v-col>-->
                                                    <v-col cols="12" sm="6">
                                                        <validation-provider v-slot="{ errors }" name="ProdRawMatType" rules="">
                                                            <v-radio-group v-model="formData.ProdRawMatType" :error-messages="errors" dense label="Production Raw Materials">
                                                                <v-radio v-for="item in $globalSettings.inspectionProdRawMatTypes" v-bind:key="item.id" :label="item.label" :value="item.id"></v-radio>
                                                            </v-radio-group>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <validation-provider v-slot="{ errors }" name="YearlyProductionCapacity" rules="">
                                                            <v-text-field v-model="formData.YearlyProductionCapacity" type="text" :error-messages="errors" label="Annual production capacity" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <validation-provider v-slot="{ errors }" name="ImportedRawMatHSCodes" rules="">
                                                            <v-textarea v-model="formData.ImportedRawMatHSCodes" type="text" :error-messages="errors" label="Imported Raw Materials with HS Codes" rows="2" :disabled="loading"></v-textarea>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <validation-provider v-slot="{ errors }" name="LocalRawMatHSCodes" rules="">
                                                            <v-textarea v-model="formData.LocalRawMatHSCodes" type="text" :error-messages="errors" label="Local Raw Materials with HS Codes" rows="2" :disabled="loading"></v-textarea>
                                                        </validation-provider>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-if="formData.EA.User.ExporterType === $globalEnums.ExporterTypes.NonTextile">
                                                    <v-col cols="12" sm="4">
                                                        <validation-provider v-slot="{ errors }" name="RawMatPercentage" rules="">
                                                            <v-text-field v-model="formData.RawMatPercentage" type="text" :error-messages="errors" label="Approx. Percentage of Imported Raw Materials" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <validation-provider v-slot="{ errors }" name="RawMatProcType" rules="">
                                                            <v-radio-group v-model="formData.RawMatProcType" :error-messages="errors" dense label="Raw Materials Processing Type" class="mt-0 pt-0">
                                                                <v-radio v-for="item in $globalSettings.inspectionRawMatProcTypes" v-bind:key="item.id" :label="item.label" :value="item.id"></v-radio>
                                                            </v-radio-group>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <validation-provider v-slot="{ errors }" name="ValueAddedPercentage" rules="">
                                                            <v-text-field v-model="formData.ValueAddedPercentage" type="text" :error-messages="errors" label="Value added percentage according to Cost Sheet" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                </v-row>
                                                <div class="mt-7 font-weight-bold">Human Resources:</div>
                                                <v-row>
                                                    <v-col cols="12" sm="4">
                                                        <validation-provider v-slot="{ errors }" name="NumberOfOfficeEmployees" rules="">
                                                            <v-text-field v-model="formData.NumberOfOfficeEmployees" type="text" :error-messages="errors" label="Office Employees" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <validation-provider v-slot="{ errors }" name="NumberOfSkilledFactoryEmployees" rules="">
                                                            <v-text-field v-model="formData.NumberOfSkilledFactoryEmployees" type="text" :error-messages="errors" label="Skilled factory employees" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <validation-provider v-slot="{ errors }" name="NumberOfUnskilledFactoryEmployees" rules="">
                                                            <v-text-field v-model="formData.NumberOfUnskilledFactoryEmployees" type="text" :error-messages="errors" label="Unskilled factory employees" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>

                                        <v-card outlined class="mb-4">
                                            <v-card-title>Up to date documents</v-card-title>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12" sm="3">
                                                        <validation-provider v-slot="{ errors }" name="TradeLicenceUpdated" rules="">
                                                            <v-select v-model="formData.TradeLicenceUpdated" :items="[{id: false, label: 'No'}, {id: true, label: 'Yes'}]" item-value="id" item-text="label" label="Trade Licence" :error-messages="errors"></v-select>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="3">
                                                        <validation-provider v-slot="{ errors }" name="ERCFileUpdated" rules="">
                                                            <v-select v-model="formData.ERCFileUpdated" :items="[{id: false, label: 'No'}, {id: true, label: 'Yes'}]" item-value="id" item-text="label" label="ERC" :error-messages="errors"></v-select>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="3">
                                                        <validation-provider v-slot="{ errors }" name="AssociationCertificateUpdated" rules="">
                                                            <v-select v-model="formData.AssociationCertificateUpdated" :items="[{id: false, label: 'No'}, {id: true, label: 'Yes'}]" item-value="id" item-text="label" label="Associated Council Member" :error-messages="errors"></v-select>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="3">
                                                        <validation-provider v-slot="{ errors }" name="BondedWarehouseLicenseUpdated" rules="">
                                                            <v-select v-model="formData.BondedWarehouseLicenseUpdated" :items="[{id: false, label: 'No'}, {id: true, label: 'Yes'}]" item-value="id" item-text="label" label="Bonded Warehouse Licence" :error-messages="errors"></v-select>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="3">
                                                        <validation-provider v-slot="{ errors }" name="FireCertificateUpdated" rules="">
                                                            <v-select v-model="formData.FireCertificateUpdated" :items="[{id: false, label: 'No'}, {id: true, label: 'Yes'}]" item-value="id" item-text="label" label="Fire Certificate" :error-messages="errors"></v-select>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="3">
                                                        <validation-provider v-slot="{ errors }" name="FireInsuranceUpdated" rules="">
                                                            <v-select v-model="formData.FireInsuranceUpdated" :items="[{id: false, label: 'No'}, {id: true, label: 'Yes'}]" item-value="id" item-text="label" label="Fire Insurance" :error-messages="errors"></v-select>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="3">
                                                        <validation-provider v-slot="{ errors }" name="TINCertificateUpdated" rules="">
                                                            <v-select v-model="formData.TINCertificateUpdated" :items="[{id: false, label: 'No'}, {id: true, label: 'Yes'}]" item-value="id" item-text="label" label="TIN" :error-messages="errors"></v-select>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="3">
                                                        <validation-provider v-slot="{ errors }" name="VATCertificateUpdated" rules="">
                                                            <v-select v-model="formData.VATCertificateUpdated" :items="[{id: false, label: 'No'}, {id: true, label: 'Yes'}]" item-value="id" item-text="label" label="VAT Registration" :error-messages="errors"></v-select>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="3">
                                                        <validation-provider v-slot="{ errors }" name="BIDAEPZRegLetterUpdated" rules="">
                                                            <v-select v-model="formData.BIDAEPZRegLetterUpdated" :items="[{id: false, label: 'No'}, {id: true, label: 'Yes'}]" item-value="id" item-text="label" label="BIDA/EPZ Reg Letter" :error-messages="errors"></v-select>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <validation-provider v-slot="{ errors }" name="ElectricityBillPaid" rules="">
                                                            <v-text-field v-model="formData.ElectricityBillPaid" type="text" :error-messages="errors" label="Proof of Electricity Bill" :disabled="loading"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>

                                        <v-card-actions class="d-flex flex-row-reverse">
                                            <v-btn v-if="formActions.formEditEnabled && !invalid && isRequiredAttachmentsUploaded && $globalHelpers.getAuthStoreValue('user_username') !== 'iia-bd' && formActions.submitEnabled" type="button" class="success" :disabled="invalid || loading" @click="prepareStatusChange">Submit Report</v-btn>
                                            <v-btn v-if="formActions.formEditEnabled" type="submit" class="secondary mr-3" :disabled="loading">Save</v-btn>
                                        </v-card-actions>
                                    </v-form>
                                </div>

                                <div v-show="selectedSectionId !== 100 && selectedSectionId !== 101">
                                    <div class="font-size-20 font-bold">{{ currentAttachmentItem.label }}</div>
                                    <v-divider class="mt-2 mb-5"></v-divider>

                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-file-input v-if="formActions.formEditEnabled" show-size :clearable="false" truncate-length="100" :label="currentAttachmentItem.formatLabel" :accept="currentAttachmentItem.formats" @change="uploadImage" v-model="attachment.attachmentInputFileName" ref="attachmentInput"></v-file-input>
                                        </v-col>
                                    </v-row>

                                    <div class="images mt-10" v-viewer="{movable: false}" v-for="item in uploadedImageAttachments" v-bind:key="item.id" v-show="selectedSectionId === item.Type">
                                        <div class="attachmentImageContainer">
                                            <img v-if="item.MimeType !== 'application/pdf'" :aspect-ratio="16/9" :src="$apiBaseURL + $globalSettings.api.endpoints.media.inspectionAttachments + item.FilePath" class="attachmentImage">
                                            <div>
                                            <v-btn v-if="formActions.formEditEnabled" type="button" fab x-small class="error pl-1 pr-0" @click="deleteAttachment(item.Id)"><v-icon small>fas fa-minus</v-icon></v-btn></div>
                                        </div>

                                    </div>
                                    <div class="clearfix"></div>
                                    <v-row class="mt-10">
                                        <v-col v-for="attachment in uploadedPdfAttachments" v-bind:key="attachment.id" v-show="selectedSectionId === attachment.Type" class="attachment attachmentPdfContainer">
                                            <iframe :src="$apiBaseURL + $globalSettings.api.endpoints.media.inspectionAttachments + attachment.FilePath" width="100%" height="500"></iframe>
                                            <v-btn v-if="formActions.formEditEnabled" type="button" fab x-small class="error pl-1 pr-0" @click="deleteAttachment(attachment.Id)"><v-icon small>fas fa-minus</v-icon></v-btn>
                                        </v-col>
                                    </v-row>

                                </div>

                                <div v-show="selectedSectionId === 101" v-if="formData.EA.StatusId > $globalEnums.ApplicationStatuses.Inspection">
                                    <div class="font-size-20 font-bold">Report Summary</div>
                                    <v-divider class="mt-2 mb-5"></v-divider>
                                    <div>{{ summaryReportContent }}</div>
                                </div>
                            </v-col>

                        </v-row>

                </validation-observer>
            </v-card-text>
        </v-card>

        <v-dialog v-model="formActions.statusChangeModal" width="500">
            <v-card v-if="!formActions.formValid">
                <v-card-title class="text-h5 grey lighten-2">
                    Incomplete Form
                </v-card-title>
                <v-card-text>
                    <div class="red--text">Please fill up all required data!</div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn type="button" class="success" @click="formActions.statusChangeModal = false">Close</v-btn>
                </v-card-actions>
            </v-card>
            <validation-observer ref="observer" v-slot="{ invalid }" v-if="formActions.formValid">
                <v-form @submit.prevent="changeStatus">
                    <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                            Submit Inspection {{ formActions.nextStatusId === $globalEnums.ApplicationStatuses.Report_Submitted? 'Report': '' }}
                        </v-card-title>

                        <v-card-text>
                            <validation-provider v-slot="{ errors }" name="Summary" :rules="{required: formActions.nextStatusId === $globalEnums.ApplicationStatuses.Report_Submitted}">
                                <v-textarea v-model="formActions.statusChangeComment" :error-messages="errors" :label="formActions.nextStatusId === $globalEnums.ApplicationStatuses.Report_Submitted? 'Summary': 'Note'" rows="5" auto-grow :disabled="loading"></v-textarea>
                            </validation-provider>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn type="submit" class="success" :disabled="invalid">Submit</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </validation-observer>
        </v-dialog>
        <v-dialog v-model="formActions.viewLetterModal" width="600">
            <v-form @submit.prevent="sendLetter">
                <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                        Send Inspection Letter
                    </v-card-title>

                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <div class="mt-2 font-italic">Send To</div>
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ formData.EA.User.Name }}</v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ formData.EA.User.Email }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle>
                                            Exporter, {{ formData.EA.User.OrganizationName }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item three-line v-for="(inspector, i) in formData.EAInspectors" :key="i">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ inspector.User.Name }}</v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ inspector.User.Email }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle>
                                            {{ $globalHelpers.getUserRoleName(inspector.User.RoleId) + (inspector.isLeader? ' (Team Leader)': '') }}, {{ inspector.User.OrganizationName }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn type="button" class="success" :disabled="loading" @click="sendLetter">Send</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="false"
                :preview-modal="true"
                :paginate-elements-by-height="1100"
                filename="Inspection Form"
                :pdf-quality="2"
                :manual-pagination="false"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px"

                @progress="pdfAppProgress($event)"
                @hasStartedGeneration="pdfAppStarted()"
                @hasGenerated="pdfAppCompleted($event)"
                ref="emailPdf"
        >
            <section slot="pdf-content" class="pdftemplate">
                <section class="pdf-item pdftemplate-heading">
                    <v-row>
                        <v-col cols="12" sm="2">
                            <img src="/images/logo.png" style="height: 50px;">
                        </v-col>
                        <v-col cols="12" sm="8">
                            <div class="pdftemplate-heading-title">Export Promotion Bureau</div>
                            <div class="pdftemplate-subheading">{{ formData.EA.User.ExporterType === $globalEnums.ExporterTypes.Textile? 'Textile': 'Policy & Planning' }} Division</div>
                            <div class="pdftemplate-address">{{ $globalHelpers.getServiceOfficeName(formData.EA.User.ServiceOfficeId) }}</div>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <img  src="/images/epb-round.png" style="height: 60px;">
                        </v-col>
                    </v-row>
                </section>
                <section class="pdf-item">
                    <div style="margin-top: 40px">Date: ...........</div>
                    <div style="margin-top: 20px" v-html="formActions.inspectionLetterBody"></div>
                    <v-row style="margin-top: 50px">
                        <v-col cols="12" sm="8"></v-col>
                        <v-col cols="12" sm="4">
                            --------------------------------<br />
                            Export Promotion Bureau,<br />
                            {{ $globalHelpers.getServiceOfficeName(formData.EA.User.ServiceOfficeId) }}
                        </v-col>
                    </v-row>
                </section>
            </section>
        </vue-html2pdf>
        <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="false"
                :preview-modal="true"
                :paginate-elements-by-height="1100"
                :filename="'Inspection of #' + formData.EA.Id"
                :pdf-quality="2"
                :manual-pagination="true"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px"

                @progress="pdfAppProgress($event)"
                @hasStartedGeneration="pdfAppStarted()"
                @hasGenerated="pdfAppCompleted($event)"
                ref="html2Pdf"
        >
            <section slot="pdf-content" class="pdftemplate">
                <section class="pdf-item pdftemplate-heading">
                    <v-row>
                        <v-col cols="12" sm="2">
                            <img src="/images/logo.png" style="height: 50px;">
                        </v-col>
                        <v-col cols="12" sm="8">
                            <div class="pdftemplate-heading-title">Export Promotion Bureau</div>
                            <div class="pdftemplate-subheading">{{ formData.EA.User.ExporterType === $globalEnums.ExporterTypes.Textile? 'Textile': 'Policy & Planning' }} Division</div>
                            <div class="pdftemplate-address">TCB Building (5th Floor), 1 Kawran Bazar, Dhaka 1215</div>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <img  src="/images/epb-round.png" style="height: 60px;">
                        </v-col>
                    </v-row>
                </section>
                <section class="pdf-item">
                    <div class="eapdf-body-heading-item-left">Inspection Report:</div>
                    <div class="eapdf-body-heading-item-right font-size-12">Printed: <span class="font-italic">{{ $globalHelpers.getDateToday('DD/MM/YYYY h:mm:ss A') }}</span></div>
                    <div class="eapdf-body-heading-item-right font-size-12 mr-5" style="margin-right: 5px;">Status: <span class="font-italic font-bold">{{ $globalHelpers.getEnumLabel($globalEnums.ApplicationStatuses, formData.StatusId) }}</span> </div>
                    <div class="clearfix"></div>
                </section>
                <div class="pdftemplate-vertical-spacer"></div>
                <section class="pdf-item">
                    <table border="1" cellspacing="0" class="eapdf-table">
                        <tr>
                            <td width="35%">Organization:</td>
                            <td>{{ formData.EA.User.OrganizationName }}</td>
                        </tr>
                        <tr>
                            <td>EPB Enrolment No (if applicable):</td>
                            <td>{{formData.EA.User.Username}}</td>
                        </tr>
                        <tr>
                            <td width="35%">Authorized Person:</td>
                            <td>
                                {{ formData.OfficePersonFName }} {{formData.OfficePersonLName}}, {{formData.OfficePersonTitle }}<br />
                                {{ formData.OfficePersonPhone }}
                            </td>
                        </tr>
                        <tr>
                            <td>Office:</td>
                            <td>{{ formData.OfficeAddress }} {{$globalHelpers.getThanaName(formData.OfficeThanaId) }}, {{ $globalHelpers.getDistrictName(formData.OfficeDistrictId) }} <br> {{ formData.OfficePhone }} <br> {{ formData.OfficeEmail }}<br> {{ formData.OfficeWebsite }}</td>
                        </tr>
                        <tr>
                            <td>Factory:</td>
                            <td>{{ formData.FactoryAddress}} {{$globalHelpers.getThanaName(formData.FactoryThanaId) }}, {{ $globalHelpers.getDistrictName(formData.FactoryDistrictId)  }} <br> {{ formData.FactoryPhone }} <br> {{ formData.FactoryEmail }}<br> {{ formData.FactoryWebsite }}</td>
                        </tr>
                        <tr>
                            <td>TIN No:</td>
                            <td>{{ formData.TINNo }}</td>
                        </tr>
                        <tr>
                            <td>BIN:</td>
                            <td>{{ formData.BINNo }}</td>
                        </tr>
                        <tr>
                            <td>Bank Name & Address:</td>
                            <td>{{formData.BankName}} {{ formData.BankAddress }}</td>
                        </tr>
                        <tr>
                            <td>Type of Organization:</td>
                            <td>{{ formData.OrgType? $globalSettings.inspectionOrgTypes.find(x => x.id === formData.OrgType).label: '' }}</td>
                        </tr>
                        <tr>
                            <td>Category of Organization:</td>
                            <td>{{ formData.OrgCat? $globalSettings.inspectionOrgCats.find(x => x.id === formData.OrgCat).label: '' }}</td>
                        </tr>
                        <tr>
                            <td>Is it in exact location?:</td>
                            <td>{{ formData.InExactLoc? 'Yes': 'No' }}</td>
                        </tr>
                        <tr>
                            <td>Is activities running?:</td>
                            <td>{{ formData.IsActive? 'Yes': 'No' }}</td>
                        </tr>
                        <tr>
                            <td>Is directly exporting?:</td>
                            <td>{{ formData.DirectlyExporting? 'Yes': 'No' }}</td>
                        </tr>

                        <tr>
                            <td>Factory type:</td>
                            <td>
                                <span v-for="(eaInspectionFactoryType, i) in formData.EAInspectionFactoryTypes" :key="i" class="text-underline pdf-margin-right-2">{{ eaInspectionFactoryType.FactoryType.Label }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Is composite?:</td>
                            <td>{{ formData.IsComposite? 'Yes': 'No' }}</td>
                        </tr>
                        <tr>
                            <td>Instrument list:</td>
                            <td>
                                <v-row class="pa-5" style="padding: 10px;">
                                    <v-col cols="12" sm="4" class="pa-0" style="padding: 2px;" v-for="machine in formData.EAInspectionMachines" :key="machine.Id">{{ machine.Description }}</v-col>
                                </v-row>
<!--                                <ol>
                                    <li v-for="machine in formData.EAInspectionMachines" :key="machine.Id">{{ machine.Description }}</li>
                                </ol>-->
                            </td>
                        </tr>
                        <tr>
                            <td>Factory Building Type:</td>
                            <td>{{ formData.FactoryBuildingType }}</td>
                        </tr>
                        <tr>
                            <td>Total Floor count:</td>
                            <td>{{ formData.FactoryBuildingFloorsCount }}</td>
                        </tr>
                        <tr>
                            <td>Production Floor count:</td>
                            <td>{{ formData.FactoryBuildingProductionFloorsCount }}</td>
                        </tr>
                        <tr>
                            <td>Floor size:</td>
                            <td>{{ formData.FactoryBuildingFloorSize }}</td>
                        </tr>
                        <tr>
                            <td>Stairs count:</td>
                            <td>{{ formData.FactoryBuildingStairsCount }}</td>
                        </tr>
                        <tr>
                            <td>Fire Equipments Details:</td>
                            <td>{{ formData.FireEquipmentsDetails }}</td>
                        </tr>

                        <tr>
                            <td>Products With HS Codes:</td>
                            <td>{{ formData.ProductsWithHSCodes }}</td>
                        </tr>
                        <tr>
                            <td>Production raw materials:</td>
                            <td>{{ formData.ProdRawMatType? $globalSettings.inspectionProdRawMatTypes.find(x => x.id === formData.ProdRawMatType).label: '' }}</td>
                        </tr>
                        <tr>
                            <td>Annual production limit:</td>
                            <td>{{ formData.YearlyProductionCapacity }}</td>
                        </tr>
                        <tr>
                            <td>Imported raw materials with HS Codes:</td>
                            <td>{{ formData.ImportedRawMatHSCodes }}</td>
                        </tr>
                        <tr>
                            <td>Local raw materials with HS Codes:</td>
                            <td>{{ formData.LocalRawMatHSCodes }}</td>
                        </tr>
                        <tr>
                            <td>Approx. percentage of imported raw materials:</td>
                            <td>{{ formData.RawMatPercentage }}</td>
                        </tr>
                        <tr>
                            <td>Raw materials processing type:</td>
                            <td>{{ formData.RawMatProcType? $globalSettings.inspectionRawMatProcTypes.find(x => x.id === formData.RawMatProcType).label: '' }}</td>
                        </tr>
                        <tr>
                            <td>Value added percentage according to Cost Sheet:</td>
                            <td>{{ formData.ValueAddedPercentage }}</td>
                        </tr>

                        <tr>
                            <td>Office employees:</td>
                            <td>{{ formData.NumberOfOfficeEmployees }}</td>
                        </tr>
                        <tr>
                            <td>Factory employees:</td>
                            <td>Skilled: {{ formData.NumberOfSkilledFactoryEmployees }}. Unskilled: {{ formData.NumberOfUnskilledFactoryEmployees }}</td>
                        </tr>
                    </table>
                    <div class="html2pdf__page-break"/>
                    <div class="pdftemplate-vertical-spacer"></div>
                    <div class="pdf-margin-top-3 pdf-padding-top-3"></div>
                    <table border="1" cellspacing="0" class="eapdf-table">
                        <tr>
                            <td>Trade license up to date?:</td>
                            <td>{{ formData.TradeLicenceUpdated? 'Yes': 'No' }}</td>
                        </tr>
                        <tr>
                            <td>ERC up to date?:</td>
                            <td>{{ formData.ERCFileUpdated? 'Yes': 'No' }}</td>
                        </tr>
                        <tr>
                            <td>Associated council membership up to date?:</td>
                            <td>{{ formData.AssociationCertificateUpdated? 'Yes': 'No' }}</td>
                        </tr>
                        <tr>
                            <td>Bonded warehouse license up to date?:</td>
                            <td>{{ formData.BondedWarehouseLicenseUpdated? 'Yes': 'No' }}</td>
                        </tr>
                        <tr>
                            <td>Fire certificate up to date?:</td>
                            <td>{{ formData.FireCertificateUpdated? 'Yes': 'No' }}</td>
                        </tr>
                        <tr>
                            <td>Fire insurance up to date?:</td>
                            <td>{{ formData.FireInsuranceUpdated? 'Yes': 'No' }}</td>
                        </tr>
                        <tr>
                            <td>TIN up to date?:</td>
                            <td>{{ formData.TINCertificateUpdated? 'Yes': 'No' }}</td>
                        </tr>
                        <tr>
                            <td>VAT registration up to date?:</td>
                            <td>{{ formData.VATCertificateUpdated? 'Yes': 'No' }}</td>
                        </tr>
                        <tr>
                            <td>BIDA/EPV reg up to date?:</td>
                            <td>{{ formData.BIDAEPZRegLetterUpdated? 'Yes': 'No' }}</td>
                        </tr>
                        <tr>
                            <td>Electricity Bill Paid?:</td>
                            <td>{{ formData.ElectricityBillPaid? 'Yes': 'No' }}</td>
                        </tr>
                    </table>

                    <div style="margin-top: 20px;">
                        <div>Note:</div>
                        {{ this.summaryReportContent }}
                    </div>
                </section>

                <div style="margin-top: 80px"></div>
                <v-row>
                    <v-col cols="12" sm="4">
                        --------------------------------<br />
                        Org. Authorized person
                    </v-col>
                    <v-col cols="12" sm="4">
                        --------------------------------<br />
                        Inspector, EPB
                    </v-col>
                    <v-col cols="12" sm="4">
                        --------------------------------<br />
                        Team Leader, EPB
                    </v-col>
                </v-row>
            </section>
        </vue-html2pdf>
    </div>

</template>

<script>
    import Vue from 'vue';
    import { required, email, min, min_value, numeric, length } from 'vee-validate/dist/rules';
    import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';

    import 'viewerjs/dist/viewer.css';
    import { directive as viewer } from "v-viewer"

    import VueHtml2pdf from 'vue-html2pdf'

    //setInteractionMode('eager');
    extend('required', {
        ...required,
        message: '{_field_} can not be empty',
    });
    extend('email', {
        ...email,
        message: 'This field must be an email'
    });
    extend('min', {
        ...min,
        message: 'This field must be {length} characters'
    });
    extend('min_value', {
        ...min_value,
        message: 'This field must be greater than {min}'
    });
    extend('numeric', {
        ...numeric,
        message: 'This field must be a number'
    });
    extend('length', {
        ...length,
        message: 'This field must be exactly {length} characters'
    });

    export default {
        name: "Inspection_view",
        data: () => ({
            loading: false,

            selectedSectionId: 100,

            formActions: {
                formEditEnabled: false,
                submitEnabled: false,
                statusChangeModal: false,
                statusChangeComment: null,
                nextStatusId: null,
                viewLetterModal: false,
                searchFactoryTypeKeyword: null,
                filteredFactoryTypes: [],
                machineDetails: null,
                hscodeSearchKeyword: null,
                hsCodeSearchResults: [],
                selectedHSCodes: [],
                HSCodeMappingsLoadedFromServer: [],
                inspectionLetterBody: '',
                formValid: false,
            },

            attachment:{
                attachmentInputFileName: null,
            },

            currentAttachmentData:{
                attachmentDataId: null,
                attachmentDataNumber: null,
                attachmentDataDate: null,
            },

            formData: {
                Id: null,
                OfficePersonFName: null,
                OfficePersonLName: null,
                OfficePersonTitle: null,
                OfficePersonPhone: null,
                OfficeAddress: null,
                OfficeDistrictId: null,
                OfficeThanaId: null,
                OfficePhone: null,
                OfficeEmail: null,
                OfficeWebsite: null,
                FactoryAddress: null,
                FactoryDistrictId: null,
                FactoryThanaId: null,
                FactoryPhone: null,
                FactoryEmail: null,
                FactoryWebsite: null,
                TINNo: null,
                BINNo: null,
                RexNo: null,
                BankName: null,
                BankAddress: null,
                OrgType: 0,
                OrgCat: 0,
                InExactLoc: false,
                IsActive: false,
                DirectlyImporting: false,
                FactoryType: 0,
                OrgInstruments: null,
                ProdRawMatType: 0,
                YearlyProductionCapacity: null,
                ImportedRawMatHSCodes: null,
                LocalRawMatHSCodes: null,
                RawMatPercentage: null,
                RawMatProcType: 0,
                ValueAddedPercentage: null,
                NumberOfOfficeEmployees: 0,
                NumberOfSkilledFactoryEmployees: 0,
                NumberOfUnskilledFactoryEmployees: 0,
                TradeLicenceUpdated: false,
                ERCFileUpdated: false,
                AssociationCertificateUpdated: false,
                BondedWarehouseLicenseUpdated: false,
                FireCertificateUpdated: false,
                FireInsuranceUpdated: false,
                TINCertificateUpdated: false,
                VATCertificateUpdated: false,
                BIDAEPZRegLetterUpdated: false,
                EA: {
                    User:{
                        OrganizationName: null,
                        ExporterType: null,
                        TINNo: null,
                        BINNo: null,
                        RexNo: null,
                    },
                    TINNo: null,
                    BINNo: null,
                    RexNo: null,
                },
                EAInspectionAttachments: [],
                EAInspectionFactoryTypes: [],
                EAInspectionMachines: [],
                HSCodeMappings: [],
            },
            factoryTypes: [],
            selectedFactoryTypes: [],
            selectedMachines: [],

            summaryReportContent: null,
            printModal: false,
        }),
        components: {
            ValidationProvider,
            ValidationObserver,
            VueHtml2pdf
        },
        directives: {
            viewer: viewer({
                debug: false,
            }),
        },
        computed:{
            attachments: function(){
                if(this.formData.EA.User.ExporterType === this.$globalEnums.ExporterTypes.Textile){
                    return this.$globalSettings.ea_inspection_textile_attachments;
                }
                if(this.formData.EA.User.ExporterType === this.$globalEnums.ExporterTypes.NonTextile){
                    return this.$globalSettings.ea_inspection_non_textile_attachments;
                }
                return [];
            },
            currentAttachmentItem: function(){
                if(this.selectedSectionId < 100) {
                    return this.attachments.find(o => o.id === this.selectedSectionId);
                }
                return {};
            },
            isRequiredAttachmentsUploaded: function () {
                for(let i = 0; i < this.attachments.length; i++){
                    if(this.attachments[i].required){
                        if(this.formData.EAInspectionAttachments.filter(e => e.Type === this.attachments[i].id).length < 1){
                            return false;
                        }
                    }
                }
                return true;
            },
            uploadedImageAttachments: function () {
                let items = [];
                for(let i = 0; i < this.formData.EAInspectionAttachments.length; i++){
                    if(this.formData.EAInspectionAttachments[i].MimeType !== 'application/pdf') {
                        items.push(this.formData.EAInspectionAttachments[i]);
                    }
                }
                return items;
            },
            uploadedPdfAttachments: function () {
                let items = [];
                for(let i = 0; i < this.formData.EAInspectionAttachments.length; i++){
                    if(this.formData.EAInspectionAttachments[i].MimeType === 'application/pdf') {
                        items.push(this.formData.EAInspectionAttachments[i]);
                    }
                }
                return items;
            },
        },
        methods: {
            selectSection: function(sectionId){
                this.selectedSectionId = sectionId;
            },
            prepareStatusChange: async function () {
                //if (this.$globalHelpers.getAuthStoreValue('user_role_id') === this.$globalEnums.roles.Exporter) {

                //}
                this.formActions.formValid = await this.$refs.observer.validate();
                if(this.formActions.formValid){
                    await this.save();
                }
                this.formActions.statusChangeComment = null;
                this.formActions.statusChangeModal = true;
            },
            changeStatus: async function () {
                this.loading = true;
                await this.$axios.post(this.$globalSettings.api.endpoints.ea.saveStatusLog, {
                    EAId: this.formData.EA.Id,
                    Comment: this.formActions.statusChangeComment,
                    StatusId: this.formActions.nextStatusId,
                }).then(async (response) => {
                    this.loading = false;
                    Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                    if (response.data.Status === 'success') {
                        this.formActions.statusChangeModal = false;
                        //await this.loadSavedData(false);
                        await this.navigateToEA();
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.$globalHelpers.processHttpErrorsToast(error);
                });
            },
            save: async function () {
                this.loading = true;
                this.formData['FactoryTypeIds'] = this.selectedFactoryTypes;
                this.formData['Machines'] = this.selectedMachines;
                this.prepareFormDataHSCodes();
                await this.$axios.post(this.$globalSettings.api.endpoints.ea.saveInspection, this.formData).then(async (response) => {
                    this.loading = false;
                    Vue.$toast.open({ message: response.data.Msg,  type: response.data.Status});
                    if(response.data.Status === 'success'){
                        await this.loadSavedData(false);
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.$globalHelpers.processHttpErrorsToast(error);
                });
            },
            uploadImage: async function (file) {
                if(file) {
                    this.loading = true;
                    let fileUpload = await this.$globalHelpers.saveFile(file, this.$globalSettings.api.endpoints.ea.saveInspectionAttachment, {
                        inspectionId: this.formData.Id,
                        type: this.selectedSectionId,
                    });
                    this.loading = false;
                    Vue.$toast.open({message: fileUpload.Msg, type: fileUpload.Status});
                    this.attachment.attachmentInputFileName = null;
                    await this.loadSavedData(false);

                }
            },
            loadSavedData: async function (loadHSCodeMappingsFromEA) {
                this.loading = true;
                await this.$axios.get(this.$globalSettings.api.endpoints.ea.getInspection, { params: { id: this.$route.params.id} }).then(async (response) => {
                    this.loading = false;
                    if (response.data.Status === 'success') {
                        this.formData = response.data.Data;
                        this.prepareAdditionalData();
                        this.prepareFormActionPermission();
                        this.loadSummaryReportFromStatusLog();
                        await this.prepareSelectedFactoryTypes();
                        this.searchFactoryType();
                        this.prepareSelectedMachines();
                        this.prepareSelectedHSCodes(loadHSCodeMappingsFromEA);
                        await this.executeTabChanges();
                    }else{
                        Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                    }
                }).catch((error) => {
                    console.log(error);
                    this.loading = false;
                    this.$globalHelpers.processHttpErrorsToast(error);
                });
            },
            prepareFormActionPermission: function(){
                /*if (this.$globalHelpers.getAuthStoreValue('user_id') === this.formData.EA.UserId && this.formData.EA.StatusId === this.$globalEnums.ApplicationStatuses.Inspection) {
                    this.formActions.formEditEnabled = true;
                    this.formActions.submitEnabled = true;
                    this.formActions.nextStatusId = this.$globalEnums.ApplicationStatuses.Inspection_Submitted;
                }else */
                if (this.formData.EAInspectors.filter(e => e.User.Id === this.$globalHelpers.getAuthStoreValue('user_id')).length > 0 && this.formData.EA.StatusId === this.$globalEnums.ApplicationStatuses.Inspection) {
                    this.formActions.formEditEnabled = true;
                    this.formActions.nextStatusId = this.$globalEnums.ApplicationStatuses.Report_Submitted;
                }else{
                    this.formActions.formEditEnabled = false;
                }

                if(this.$globalHelpers.getAuthStoreValue('user_username') === 'iia-bd'){
                    this.formActions.formEditEnabled = true;
                }

                for(let i = 0; i < this.formData.EAInspectors.length; i++){
                    if(this.formData.EAInspectors[i].User.Id === this.$globalHelpers.getAuthStoreValue('user_id')){
                        this.formActions.submitEnabled = this.formData.EAInspectors[i].isLeader === true;
                    }
                }
            },
            loadSummaryReportFromStatusLog: function (){
                if(this.formData.EA.EAStatusLogs.length > 0) {
                    let statusLog = this.formData.EA.EAStatusLogs.find(x => x.StatusId === this.$globalEnums.ApplicationStatuses.Report_Submitted);
                    //console.log(statusLog);
                    if(statusLog) {
                        this.summaryReportContent = statusLog.Comment;
                    }
                }
            },

            searchFactoryType: function (){
                this.formActions.filteredFactoryTypes = [];
                if(!this.$globalHelpers.isEmptyOrUndefined(this.formActions.searchFactoryTypeKeyword)){
                    console.log(`keyword: ${this.formActions.searchFactoryTypeKeyword}`);
                    for(let i = 0; i < this.factoryTypes.length; i++){
                        if(this.factoryTypes[i].Label.toUpperCase().includes(this.formActions.searchFactoryTypeKeyword.toUpperCase())){
                            //console.log(this.formActions.searchFactoryTypeKeyword);
                            //console.log(this.factoryTypes[i].Label);
                            this.formActions.filteredFactoryTypes.push(this.factoryTypes[i]);
                        }
                    }
                }else{
                    this.formActions.filteredFactoryTypes = this.factoryTypes;
                    console.log(`keyword: empty`);
                }
                //this.prepareSelectedTeamMembers();
            },
            clearFactoryTypeSearch: function (){
                this.formActions.searchFactoryTypeKeyword = null;
                this.searchFactoryType();
            },

            prepareSelectedFactoryTypes: async function () {
                //this.factoryTypes = this.formData.EA.User.ExporterType === this.$globalEnums.ExporterTypes.Textile? this.$globalSettings.textileFactoryTypes: this.$globalSettings.nonTextileFactoryTypes;
                this.factoryTypes = await this.$globalHelpers.getFactoryTypes(this.formData.EA.User.ExporterType === this.$globalEnums.ExporterTypes.Textile? this.$globalEnums.ExporterTypes.Textile: this.$globalEnums.ExporterTypes.NonTextile, true);
                this.selectedFactoryTypes = [];
                for (let i = 0; i < this.formData.EAInspectionFactoryTypes.length; i++) {
                    this.selectedFactoryTypes.push(this.formData.EAInspectionFactoryTypes[i].FactoryTypeId);
                }
            },
            prepareSelectedMachines: function(){
                this.selectedMachines = [];
                for(let i = 0; i < this.formData.EAInspectionMachines.length; i++){
                    this.selectedMachines.push(this.formData.EAInspectionMachines[i].Description);
                }
            },
            isAttachmentSectionComplete: function (id) {
                return this.formData.EAInspectionAttachments.filter(e => e.Type === id).length > 0;
            },
            deleteAttachment: async function (id) {
                if (confirm("Are your sure that you want to delete this file?") === true) {
                    this.loading = true;
                    await this.$axios.get(this.$globalSettings.api.endpoints.ea.deleteInspectionAttachment, {params: {id: id}}).then(async (response) => {
                        this.loading = false;
                        Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                        if (response.data.Status === 'success') {
                            await this.loadSavedData(false);
                        }
                    }).catch((error) => {
                        this.loading = false;
                        this.$globalHelpers.processHttpErrorsToast(error);
                    });
                }
            },
            /*getAttachmentDataItem: function(type){
                for(let i =0; i < this.formData.EAAttachmentDatas.length; i++){
                    if()
                }
            },*/
            addMachine: function(){
                this.selectedMachines.push(this.formActions.machineDetails);
                this.formActions.machineDetails = null;
            },
            deleteMachine: function(index){
                this.selectedMachines.splice(index, 1);
            },
            searchHSCode: async function(){
                if(this.formActions.hscodeSearchKeyword.length < 4){
                    this.formActions.hsCodeSearchResults = [];
                    return false;
                }
                this.loading = true;
                this.formActions.hsCodeSearchResults = [];
                await this.$axios.get(this.$globalSettings.api.endpoints.hscode.search, { params: { keyword: this.formActions.hscodeSearchKeyword} }).then(async (response) => {
                    this.loading = false;
                    if (response.data.Status === 'success') {
                        this.formActions.hsCodeSearchResults = response.data.Data;
                    }else{
                        Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.$globalHelpers.processHttpErrorsToast(error);
                });
            },
            prepareSelectedHSCodes: function (loadHSCodeMappingsFromEA){
                this.formActions.HSCodeMappingsLoadedFromServer = this.formData.HSCodeMappings;
                this.formActions.selectedHSCodes = [];

                if(loadHSCodeMappingsFromEA && this.formData.EA.StatusId === this.$globalEnums.ApplicationStatuses.Inspection && this.formData.HSCodeMappings.length === 0){
                    this.formData.HSCodeMappings = this.formData.EA.HSCodeMappings;
                }

                for(let i = 0; i < this.formData.HSCodeMappings.length; i++){
                    this.formActions.selectedHSCodes.push(this.formData.HSCodeMappings[i].HSCode);
                }
            },
            prepareAdditionalData: function (){
                if(this.formData.EA.StatusId === this.$globalEnums.ApplicationStatuses.Inspection){
                    if(this.formData.OfficePersonFName === null && this.formData.EA.User.Name !== null){
                        if (this.formData.EA.User.Name.indexOf(' ') > -1)
                        {
                            this.formData.OfficePersonFName = this.formData.EA.User.Name.split(' ').slice(0, -1).join(' ');
                            this.formData.OfficePersonLName = this.formData.EA.User.Name.split(' ').slice(-1).join(' ');
                        }else{
                            this.formData.OfficePersonFName = this.formData.EA.User.Name;
                        }
                    }
                    if(this.formData.OfficePersonPhone === null){
                        this.formData.OfficePersonPhone = this.formData.EA.User.Mobile? this.formData.EA.User.Mobile: this.formData.EA.User.Phone;
                    }
                    if(this.formData.OfficeAddress === null){
                        this.formData.OfficeAddress = this.formData.EA.OfficeAddress;
                    }
                    if(!this.formData.OfficeDistrictId){
                        this.formData.OfficeDistrictId = this.formData.EA.OfficeDistrictId;
                    }
                    if(!this.formData.OfficeThanaId){
                        this.formData.OfficeThanaId = this.formData.EA.OfficeThanaId;
                    }
                    if(this.formData.OfficePhone === null){
                        this.formData.OfficePhone = this.formData.EA.OfficePhone;
                    }
                    if(this.formData.OfficeEmail === null){
                        this.formData.OfficeEmail = this.formData.EA.OfficeEmail;
                    }
                    if(this.formData.OfficeWebsite === null){
                        this.formData.OfficeWebsite = this.formData.EA.OfficeWebsite;
                    }
                    if(this.formData.FactoryAddress === null){
                        this.formData.FactoryAddress = this.formData.EA.FactoryAddress;
                    }
                    if(!this.formData.FactoryDistrictId){
                        this.formData.FactoryDistrictId = this.formData.EA.FactoryDistrictId;
                    }
                    if(!this.formData.FactoryThanaId){
                        this.formData.FactoryThanaId = this.formData.EA.FactoryThanaId;
                    }
                    if(this.formData.FactoryPhone === null){
                        this.formData.FactoryPhone = this.formData.EA.FactoryPhone;
                    }
                    if(this.formData.FactoryEmail === null){
                        this.formData.FactoryEmail = this.formData.EA.FactoryEmail;
                    }
                    if(this.formData.FactoryWebsite === null){
                        this.formData.FactoryWebsite = this.formData.EA.FactoryWebsite;
                    }
                    if(this.formData.TINNo === null){
                        this.formData.TINNo = this.formData.EA.TINNo;
                    }
                    if(this.formData.BINNo === null){
                        this.formData.BINNo = this.formData.EA.BINNo;
                    }
                    if(this.formData.RexNo === null){
                        this.formData.RexNo = this.formData.EA.RexNo;
                    }
                }
            },
            executeTabChanges: async function (){
                if(this.formData.EA.StatusId === this.$globalEnums.ApplicationStatuses.Inspection && this.formActions.formEditEnabled){
                    console.log(this.formActions.HSCodeMappingsLoadedFromServer.length, this.isRequiredAttachmentsUploaded);
                    if(!this.isRequiredAttachmentsUploaded && this.formActions.HSCodeMappingsLoadedFromServer.length > 0){
                        for(let i = 0; i < this.attachments.length; i++){
                            if(this.attachments[i].required && !this.isAttachmentSectionComplete(this.attachments[i].id)){
                                this.selectSection(this.attachments[i].id);
                                console.log('current section', this.attachments[i].label);
                                break;
                            }
                        }
                    }
                    else{
                        console.log('current section', 'Inquiry Report');
                        this.selectSection(100);
                    }
                }
            },
            formCopyOfficeToFactory: function (){
                this.formData.FactoryAddress = this.formData.OfficeAddress;
                this.formData.FactoryEmail = this.formData.OfficeEmail;
                this.formData.FactoryPhone = this.formData.OfficePhone;
                this.formData.FactoryWebsite = this.formData.OfficeWebsite;
                this.formData.FactoryDistrictId = this.formData.OfficeDistrictId;
                this.formData.FactoryThanaId = this.formData.OfficeThanaId;
            },
            addHSCode: async function(hsCode){
                if(this.formActions.selectedHSCodes.some(x => x.Id === hsCode.Id)){
                    Vue.$toast.open({message: 'You have already added this HS code', type: 'warning'});
                }else{
                    this.formActions.selectedHSCodes.push(hsCode);
                }
            },
            removeHSCode: async function(hsCode){
                this.formActions.selectedHSCodes = this.formActions.selectedHSCodes.filter(x => x.Id !== hsCode.Id);
            },
            prepareFormDataHSCodes: function (){
                this.formData['HSCodeIds'] = [];
                for(let i = 0; i < this.formActions.selectedHSCodes.length; i++){
                    this.formData.HSCodeIds.push(this.formActions.selectedHSCodes[i].Id);
                }
            },
            pdfApplication: async function () {
                await this.$refs.html2Pdf.generatePdf()
            },
            pdfLetter: async function (type) {
                this.loading = true;
                await this.$axios.get(this.$globalSettings.api.endpoints.ea.getInspectionLetter, { params: { inspectionId: this.formData.Id, type: type} }).then(async (response) => {
                    this.loading = false;
                    if (response.data.Status === 'success') {
                        this.formActions.inspectionLetterBody = response.data.Data;
                        await this.$refs.emailPdf.generatePdf();
                    }else{
                        Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.$globalHelpers.processHttpErrorsToast(error);
                });

                //
            },
            pdfAppStarted: function () {
                console.log('PDF Started');
                this.loading = true;
            },
            pdfAppProgress: function (event) {
                console.log(event);
                console.log('PDF Progress');
                this.loading = event > 99? this.loading = false: this.loading = true;
            },
            pdfAppCompleted: function (event) {
                console.log(event);
                console.log('PDF Completed');
                this.loading = false;
            },
            navigationBack: async function () {
                this.$router.go(-1);
            },
            navigateToEA: async function () {
                await this.$router.push({name: 'App.EA', params: {id: this.formData.EAId}});
            },
            sendLetter: async function () {
                this.loading = true;
                await this.$axios.get(this.$globalSettings.api.endpoints.ea.sendInspectionLetter, {params: {inspectionId: this.formData.Id}}).then(async (response) => {
                    this.loading = false;
                    Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                    if (response.data.Status === 'success') {
                        this.formActions.viewLetterModal = false;
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.$globalHelpers.processHttpErrorsToast(error);
                });
            },
        },
        async created() {
            await this.loadSavedData(true);
        }
    }
</script>

<style scoped>
    .app-name{
        color: #794986;
        font-size: 20px;
    }
    .background{
        background: rgb(101,74,134);
        background: linear-gradient(228deg, rgba(101,74,134,1) 0%, rgba(84,66,145,1) 100%);
    }
    .login-left-bg{

    }
    .login-right-bg{
        background: #512DA8;
    }
    .file-input{
        max-width: 375px;
    }
</style>